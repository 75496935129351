import React from 'react'
import winners from "../../Assets/Data/LuckyDrawWinnersData"
import winnerframe from "../../Assets/winnerframe.png";

const LuckyDrawWinners = () => {
  return (
    <div className='container-fluid'>
        <h1 className='text-center mt-5'>Lucky Draw Winners</h1>
        <div className="row px-5">
            {winners.map((winner) => (
          <div key={winner.id} className="col-md-3 my-4">
             <div className="recent-winner text-center">
              <img
                src={winner.imgSource}
                className="img-fluid winnerImg"
                alt=""
              />
              <div className="winner-frame">
                <img src={winnerframe} className="img-fluid" alt="" />
              </div>
              <div className="winner-detail mt-2">
                <p>{winner.name}</p>
                <span>{winner.prize}</span>
              </div>
            </div>
          </div>
        ))}
        </div>
    </div>
  )
}

export default LuckyDrawWinners