const countries = [
    { code: "", callingCode: "" },
    { code: "AF", callingCode: "93" },
    { code: "AL", callingCode: "355" },
    { code: "DZ", callingCode: "213" },
    // { code: "AS", callingCode: "1-684" },
    { code: "AD", callingCode: "376" },
    { code: "AO", callingCode: "244" },
    // { code: "AI", callingCode: "1-264" },
    { code: "AQ", callingCode: "672" },
    // { code: "AG", callingCode: "1-268" },
    { code: "AR", callingCode: "54" },
    { code: "AM", callingCode: "374" },
    { code: "AW", callingCode: "297" },
    { code: "AU", callingCode: "61" },
    { code: "AT", callingCode: "43" },
    { code: "AZ", callingCode: "994" },
    // { code: "BS", callingCode: "1-242" },
    { code: "BH", callingCode: "973" },
    { code: "BD", callingCode: "880" },
    // { code: "BB", callingCode: "1-246" },
    { code: "BY", callingCode: "375" },
    { code: "BE", callingCode: "32" },
    { code: "BZ", callingCode: "501" },
    { code: "BJ", callingCode: "229" },
    // { code: "BM", callingCode: "1-441" },
    { code: "BT", callingCode: "975" },
    { code: "BO", callingCode: "591" },
    { code: "BA", callingCode: "387" },
    { code: "BW", callingCode: "267" },
    { code: "BR", callingCode: "55" },
    { code: "IO", callingCode: "246" },
    { code: "BN", callingCode: "673" },
    { code: "BG", callingCode: "359" },
    { code: "BF", callingCode: "226" },
    { code: "BI", callingCode: "257" },
    { code: "KH", callingCode: "855" },
    { code: "CM", callingCode: "237" },
    { code: "CA", callingCode: "1" },
    { code: "CV", callingCode: "238" },
    // { code: "KY", callingCode: "1-345" },
    { code: "CF", callingCode: "236" },
    { code: "TD", callingCode: "235" },
    { code: "CL", callingCode: "56" },
    { code: "CN", callingCode: "86" },
    { code: "CO", callingCode: "57" },
    { code: "KM", callingCode: "269" },
    { code: "CG", callingCode: "242" },
    { code: "CD", callingCode: "243" },
    { code: "CK", callingCode: "682" },
    { code: "CR", callingCode: "506" },
    { code: "CI", callingCode: "225" },
    { code: "HR", callingCode: "385" },
    { code: "CU", callingCode: "53" },
    { code: "CY", callingCode: "357" },
    { code: "CZ", callingCode: "420" },
    { code: "DK", callingCode: "45" },
    { code: "DJ", callingCode: "253" },
    // { code: "DM", callingCode: "1-767" },
    // { code: "DO", callingCode: "1-809" },
    { code: "EC", callingCode: "593" },
    { code: "EG", callingCode: "20" },
    { code: "SV", callingCode: "503" },
    { code: "GQ", callingCode: "240" },
    { code: "ER", callingCode: "291" },
    { code: "EE", callingCode: "372" },
    { code: "ET", callingCode: "251" },
    { code: "FJ", callingCode: "679" },
    { code: "FI", callingCode: "358" },
    { code: "FR", callingCode: "33" },
    { code: "GA", callingCode: "241" },
    { code: "GM", callingCode: "220" },
    { code: "GE", callingCode: "995" },
    { code: "DE", callingCode: "49" },
    { code: "GH", callingCode: "233" },
    { code: "GR", callingCode: "30" },
    // { code: "GD", callingCode: "1-473" },
    { code: "GT", callingCode: "502" },
    { code: "GN", callingCode: "224" },
    { code: "GW", callingCode: "245" },
    { code: "GY", callingCode: "592" },
    { code: "HT", callingCode: "509" },
    { code: "HN", callingCode: "504" },
    { code: "HU", callingCode: "36" },
    { code: "IS", callingCode: "354" },
    { code: "IN", callingCode: "91" },
    { code: "ID", callingCode: "62" },
    { code: "IR", callingCode: "98" },
    { code: "IQ", callingCode: "964" },
    { code: "IE", callingCode: "353" },
    { code: "IL", callingCode: "972" },
    { code: "IT", callingCode: "39" },
    // { code: "JM", callingCode: "1-876" },
    { code: "JP", callingCode: "81" },
    { code: "JO", callingCode: "962" },
    { code: "KZ", callingCode: "7" },
    { code: "KE", callingCode: "254" },
    { code: "KI", callingCode: "686" },
    { code: "KR", callingCode: "82" },
    { code: "KW", callingCode: "965" },
    { code: "KG", callingCode: "996" },
    { code: "LA", callingCode: "856" },
    { code: "LV", callingCode: "371" },
    { code: "LB", callingCode: "961" },
    { code: "LS", callingCode: "266" },
    { code: "LR", callingCode: "231" },
    { code: "LY", callingCode: "218" },
    { code: "LI", callingCode: "423" },
    { code: "LT", callingCode: "370" },
    { code: "LU", callingCode: "352" },
    { code: "MG", callingCode: "261" },
    { code: "MW", callingCode: "265" },
    { code: "MY", callingCode: "60" },
    { code: "MV", callingCode: "960" },
    { code: "ML", callingCode: "223" },
    { code: "MT", callingCode: "356" },
    { code: "MH", callingCode: "692" },
    { code: "MR", callingCode: "222" },
    { code: "MU", callingCode: "230" },
    { code: "MX", callingCode: "52" },
    { code: "MD", callingCode: "373" },
    { code: "MC", callingCode: "377" },
    { code: "MN", callingCode: "976" },
    { code: "ME", callingCode: "382" },
    { code: "MA", callingCode: "212" },
    { code: "MZ", callingCode: "258" },
    { code: "MM", callingCode: "95" },
    { code: "NA", callingCode: "264" },
    { code: "NR", callingCode: "674" },
    { code: "NP", callingCode: "977" },
    { code: "NL", callingCode: "31" },
    { code: "NZ", callingCode: "64" },
    { code: "NI", callingCode: "505" },
    { code: "NE", callingCode: "227" },
    { code: "NG", callingCode: "234" },
    { code: "KP", callingCode: "850" },
    { code: "NO", callingCode: "47" },
    { code: "OM", callingCode: "968" },
    { code: "PK", callingCode: "92" },
    { code: "PW", callingCode: "680" },
    { code: "PA", callingCode: "507" },
    { code: "PG", callingCode: "675" },
    { code: "PY", callingCode: "595" },
    { code: "PE", callingCode: "51" },
    { code: "PH", callingCode: "63" },
    { code: "PL", callingCode: "48" },
    { code: "PT", callingCode: "351" },
    { code: "QA", callingCode: "974" },
    { code: "RO", callingCode: "40" },
    { code: "RU", callingCode: "7" },
    { code: "RW", callingCode: "250" },
    // { code: "KN", callingCode: "1-869" },
    // { code: "LC", callingCode: "1-758" },
    // { code: "VC", callingCode: "1-784" },
    { code: "WS", callingCode: "685" },
    { code: "SM", callingCode: "378" },
    { code: "ST", callingCode: "239" },
    { code: "SA", callingCode: "966" },
    { code: "SN", callingCode: "221" },
    { code: "RS", callingCode: "381" },
    { code: "SC", callingCode: "248" },
    { code: "SL", callingCode: "232" },
    { code: "SG", callingCode: "65" },
    { code: "SK", callingCode: "421" },
    { code: "SI", callingCode: "386" },
    { code: "SB", callingCode: "677" },
    { code: "SO", callingCode: "252" },
    { code: "ZA", callingCode: "27" },
    { code: "SS", callingCode: "211" },
    { code: "ES", callingCode: "34" },
    { code: "LK", callingCode: "94" },
    { code: "SD", callingCode: "249" },
    { code: "SR", callingCode: "597" },
    { code: "SZ", callingCode: "268" },
    { code: "SE", callingCode: "46" },
    { code: "CH", callingCode: "41" },
    { code: "SY", callingCode: "963" },
    { code: "TW", callingCode: "886" },
    { code: "TJ", callingCode: "992" },
    { code: "TZ", callingCode: "255" },
    { code: "TH", callingCode: "66" },
    { code: "TL", callingCode: "670" },
    { code: "TG", callingCode: "228" },
    { code: "TO", callingCode: "676" },
    // { code: "TT", callingCode: "1-868" },
    { code: "TN", callingCode: "216" },
    { code: "TR", callingCode: "90" },
    { code: "TM", callingCode: "993" },
    { code: "TV", callingCode: "688" },
    { code: "UG", callingCode: "256" },
    { code: "UA", callingCode: "380" },
    { code: "AE", callingCode: "971" },
    { code: "GB", callingCode: "44" },
    { code: "US", callingCode: "1" },
    { code: "UY", callingCode: "598" },
    { code: "UZ", callingCode: "998" },
    { code: "VU", callingCode: "678" },
    { code: "VA", callingCode: "379" },
    { code: "VE", callingCode: "58" },
    { code: "VN", callingCode: "84" },
    { code: "YE", callingCode: "967" },
    { code: "ZM", callingCode: "260" },
    { code: "ZW", callingCode: "263" },
  ];
  export default countries;