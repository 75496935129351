import React, { useEffect, useState } from "react";
import HeroSlider from "../../Components/HeroSlider/HeroSlider";
import WinnersSection from "../../Components/WinnersSection/WinnersSection";
import ProductCard from "../../Components/ProductCard/ProductCard";
import { Container, Row } from "react-bootstrap";
import HowItWorks from "../../Components/HowItWorks/HowItWorks";
import YoutubeVideo from "../../Components/YoutubeSection/YoutubeVideo";
import productsData from "../../Assets/Data/ProductsData.json";
import DownloadSecton from "../../Components/DownloadSection/DownloadSecton";
import RecentWinners from "../../Components/RecentWinners/RecentWinners";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Blogs from "../../Components/Blogs/Blogs";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const zoomLevel = window.devicePixelRatio || 1;
      setIsZoomed(zoomLevel >= 1.5 || zoomLevel >= 2);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let navigate = useNavigate();
  return (
    <>
      <HeroSlider />
      <WinnersSection />
      <Container className="mb-md-5 mb-3">
        <Row>
          <h1 className="Prizes text-center">Limited-Time Prize Draws</h1>
          {productsData.products.map((product) => (
            <div key={product.id} className={isZoomed ? "col-12" : "col-md-6"}>
              <ProductCard
                title={product.title}
                price={product.price}
                seller={product.seller}
                description={product.description}
                competitors={product.competitors}
                total={product.total}
              />
            </div>
          ))}
        </Row>
        <div className="text-center">
          <button
            className="btn btn-outline-warning btn-lg float-center mt-5"
            onClick={() => {
              navigate("/prizes");
            }}
          >
            EXPLORE MORE
          </button>
        </div>
      </Container>
      <h1 className="Recent-title text-center mb-5">How It Works</h1>
      <HowItWorks />
      <YoutubeVideo />
      {/* {state} */}
      <DownloadSecton />
      <RecentWinners />
      <Testimonials />
      <Blogs />
    </>
  );
};

export default LandingPage;
