import axios from "axios";

const baseUrl = "http://172.16.1.114:3000";
export function calculatePercentage(value, total) {
  if (total === 0 || total === undefined || total === null) {
    return 0; // Avoid division by zero
  }
  return Math.round((value / total) * 100);
}
export async function Post(route, body) {
  try {
    const res = await axios.post(`${baseUrl}/${route}`, body);
    console.log(res);
    return res;
  } catch (err) {
    console.error(err);
    return err.response || { status: 500, message: "Unknown error occurred" };
  }
}
export async function Get(route) {
  try {
    const res = await axios.get(`${baseUrl}/${route}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return res;
  } catch (err) {
    return err.response || { status: 500, message: "internal server error" };
  }
}
