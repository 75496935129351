import React, { useEffect, useState } from "react";
import ProductCard from "../../Components/ProductCard/ProductCard";
import productsData from "../../Assets/Data/ProductsData.json";
const PrizeDraws = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const zoomLevel = window.devicePixelRatio || 1;
      setIsZoomed(zoomLevel >= 1.5 || zoomLevel >= 2);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container">
      {/* <h1 className="Prizes text-center">Limited-Time Prize Draws</h1> */}
      <div className="row">
        <h1 className="Prizes text-center mt-4">Limited-Time Prize Draws</h1>
        {productsData.products.map((product) => (
          <div key={product.id} className={isZoomed ? "col-12" : "col-md-6"}>
            <ProductCard
              title={product.title}
              price={product.price}
              seller={product.seller}
              description={product.description}
              competitors={product.competitors}
              total={product.total}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrizeDraws;
