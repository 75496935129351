import React, { useEffect, useState } from "react";
import "./ProductCard.css";
import image from "../../Assets/Image.png";
import { Button } from "react-bootstrap";
import { calculatePercentage } from "../../Utils/Utils";

const ProductCard = ({ title, description, competitors, total }) => {
  let progressBar = calculatePercentage(competitors, total);
  // eslint-disable-next-line
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const zoomLevel = window.devicePixelRatio || 1;
      const root = document.documentElement;
      root.style.setProperty("--zoom-scale", zoomLevel >= 1.75 ? "1.1" : "1");
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="product-card">
      <div className="product-image">
        <img src={image} alt={title} className="" />
      </div>
      <div className={`product-details`}>
        <div className="details-header pb-0 mb-0">
          <div className="product-title w-75 mt-2 font-weight-bold">
            <h4>{title}</h4>
          </div>
          <div className="product-seller w-50">
            <p className="compe">
              <span>{competitors}</span> Entries out of <span> {total}</span>
            </p>
            <div className="progress progress-height">
              <div
                className={`progress-bar progress-bar-striped progress-bar-animated ${
                  progressBar >= 80
                    ? "bg-danger"
                    : progressBar >= 70
                    ? "bg-warning"
                    : progressBar <= 25
                    ? "bg-success"
                    : ""
                }`}
                role="progressbar"
                aria-valuenow="35"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${progressBar}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="row mt-2" >
          <div className="col-6">
            <h5 className="price">Ticket Price</h5>
          </div>
          <div className="col-6">
            <h5 className="Black text-end">PKR 100</h5>
          </div>
        </div>
        <p className="product-description mt-0">{description}</p>
        <div className="details-actions">
          <Button className="btn-details" variant="secondary">
            Details
          </Button>
          <Button className="btn-add-to-cart" variant="warning">
            Add to Cart
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
