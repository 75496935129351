const LuckyDrawWinners = [
  {
    id: 1,
    name: "Emma 1",
    prize: "Bike",
    imgSource: require("../Group.png"),
  },
  {
    id: 2,
    name: "Emma 2",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 3,
    name: "Emma 3",
    prize: "Air pods",
    imgSource: require("../Group.png"),
  },
  {
    id: 4,
    name: "Emma 4",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 5,
    name: "Emma 5",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 6,
    name: "Emma 6",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 7,
    name: "Emma 7",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 8,
    name: "Emma 8",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 9,
    name: "Emma 9",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 10,
    name: "Emma 10",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 11,
    name: "Emma 11",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 12,
    name: "Emma 12",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
];
export default LuckyDrawWinners;
