import React from "react";
import winnerframe from "../../Assets/winnerframe.png";
import enaamWinners from "../../Assets/Data/WinnersData";
import { useLocation } from "react-router-dom";

const RecentWinners = () => {
  const location = useLocation();
  const pathName = location.pathname;
  // console.log("location : " ,  location, pathName);

  return (
    <div className="container-fluid px-5 py-0 text-center">
      <h1 className="Recent-title mb-5">Recent Winners</h1>
      <div className="row">
        {enaamWinners.map((winner) => (
          <div key={winner.id} className="col-md-3 mb-5">
            <div className="recent-winner text-center">
              <img
                src={winner.imgSource}
                className="img-fluid winnerImg"
                alt=""
              />
              <div className="winner-frame">
                <img src={winnerframe} className="img-fluid" alt="" />
              </div>
              <div className="winner-detail mt-2">
                <p>{winner.name}</p>
                <span>{winner.prize}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {pathName === "/winners" || (
        <button className="btn btn-outline-warning btn-lg float-center mt-4">
          EXPLORE MORE
        </button>
      )}
    </div>
  );
};

export default RecentWinners;
