import React, { useState } from "react";
import clrlogo from "../../Assets/enaam.pk icon.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosCart } from "react-icons/io";
import { User } from "../../Atom";
import { useRecoilState } from "recoil";
import { AiOutlineLogout, AiOutlineUser } from "react-icons/ai";

import { FaRegBell, FaUser } from "react-icons/fa";

const Navbar = () => {
  const [user] = useRecoilState(User);
  const [dropDown, setDropDown] = useState(false);
  const [logedIn, setLogedIn] = useState(localStorage.getItem("LogedIn"));
  const navigate = useNavigate();
  const [isBellDropdownOpen, setBellDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setUserDropdownOpen] = useState(false);

  const toggleBellDropdown = () => setBellDropdownOpen(!isBellDropdownOpen);
  const toggleUserDropdown = () => setUserDropdownOpen(!isUserDropdownOpen);
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link className="logoImage ms-4" to="/">
          <img src={clrlogo} className="img-fluid" alt="Logo" />
        </Link>
        <div
          className="navbar-toggler border-0 me-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon text-dark"></span>
        </div>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto d-flex align-items-center">
            <li className="nav-item" style={{ marginRight: "15px" }}>
              <NavLink to="/" className="nav-link" activeClassName="active">
                Home
              </NavLink>
            </li>
            <li className="nav-item" style={{ marginRight: "15px" }}>
              <NavLink
                to="/prizes"
                className="nav-link"
                activeClassName="active"
              >
                Prizes
              </NavLink>
            </li>
            <li
              className="nav-item dropdown"
              style={{ marginRight: "15px", position: "relative" }}
              onMouseEnter={(e) => {
                e.currentTarget.classList.add("show");
                setDropDown(true);
              }}
              onMouseLeave={(e) => {
                e.currentTarget.classList.remove("show");
                setDropDown(false);
              }}
            >
              <NavLink className="dropdown" id="navbarDropdown">
                Winners
                {dropDown ? (
                  <IoIosArrowUp size={20} />
                ) : (
                  <IoIosArrowDown size={20} />
                )}
              </NavLink>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <NavLink
                    to="/hang-tower-winners"
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Hang Tower Winners
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/winners"
                    className="dropdown-item"
                    activeClassName="active"
                  >
                    Competition Winners
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item" style={{ marginRight: "15px" }}>
              <NavLink
                to="/offers"
                className="nav-link"
                activeClassName="active"
              >
                Offers
              </NavLink>
            </li>
            <li className="nav-item" style={{ marginRight: "15px" }}>
              <NavLink
                to="/about-us"
                className="nav-link"
                activeClassName="active"
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact-us"
                className="nav-link"
                // eslint-disable-next-line
                activeClassName="active"
              >
                Contact
              </NavLink>
            </li>
            <li className="nav me-3">
              <NavLink to="/cart" className="nav-link" activeClassName="active">
                {/* <img src={cartbag} alt="Cart" /> */}
                <IoIosCart size={25} className="cart-icon" />
              </NavLink>
            </li>
            <li className="nav-button">
              {logedIn ? (
                <div className="d-flex align-items-center">
                  <div className="position-relative">
                    <FaRegBell
                      size={22}
                      className="me-4 cart-icon"
                      onClick={toggleBellDropdown}
                      style={{ cursor: "pointer" }}
                    />
                    {isBellDropdownOpen && (
                      <div
                        className="dropdown-menu show"
                        style={{
                          position: "absolute",
                          top: "30px",
                          right: "0",
                        }}
                      >
                        <div className="dropdown-item">Notification 1</div>
                        <div className="dropdown-item">Notification 2</div>
                        <div className="dropdown-item">Notification 3</div>
                      </div>
                    )}
                  </div>

                  {/* User Icon */}
                  <div className="position-relative">
                    <AiOutlineUser
                      size={25}
                      className="me-5 cart-icon"
                      onClick={toggleUserDropdown}
                      style={{ cursor: "pointer" }}
                    />
                    {isUserDropdownOpen && (
                      <div
                        className="dropdown-menu show"
                        style={{
                          position: "absolute",
                          top: "30px",
                          right: "0",
                        }}
                      >
                        <div className="dropdown-item">
                          <FaUser /> {user.name? user.name:"User Name"}
                        </div>
                        <hr />
                        <div className="dropdown-item">Profile</div>
                        <div className="dropdown-item">History</div>
                        <div
                          className="dropdown-item dropdown-item-logout"
                          onClick={() => {
                            localStorage.clear();
                            setLogedIn(false);
                          }}
                        >
                          <AiOutlineLogout size={25} className="me-1" /> Logout
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="btn btn-outline-warning me-4 nav-button text-dark"
                >
                  Login
                </button>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
