const enaamWinners = [
  {
    id: 1,
    name: "Emma 1",
    prize: "Bike",
    imgSource: require("../Group.png"),
  },
  {
    id: 2,
    name: "Emma 2",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
  {
    id: 3,
    name: "Emma 3",
    prize: "Air pods",
    imgSource: require("../Group.png"),
  },
  {
    id: 4,
    name: "Emma 4",
    prize: "Mobile",
    imgSource: require("../Group.png"),
  },
];
export default enaamWinners;
