import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { IoLocationOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { CiMail } from "react-icons/ci";
import { FaYoutube } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";

const ContactForm = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    console.log("Form submitted:", values);
    alert("Message sent successfully!");
    resetForm();
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 bg-light">
      <div
        className="card p-4 shadow "
        style={{ maxWidth: "70%", width: "100%" }}
      >
        <h3 className="text-center fw-bolder ">Get In Touch</h3>
        <h4>Leave us a message</h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="row">
              <div className="col-md-6">
                <div className=" mb-3">
                  <Field
                    name="name"
                    id="name"
                    as={TextField}
                    label="Name"
                    fullWidth
                    margin="normal"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="email"
                    id="email"
                    as={TextField}
                    label="Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="message"
                    id="message"
                    as={TextField}
                    label="Your Message"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                  />
                  <button
                    type="submit"
                    className="btn btn-primary w-100 btn-lg mt-2"
                  >
                    Send
                  </button>
                </div>
              </div>
              <div className="col-md-6 location-text ">
                <div className="d-flex">
                  <IoLocationOutline size={24} />
                  <p>Amanah Mall, Model Town Link, Road, Lahore</p>
                </div>
                <div className="d-flex">
                  <LuPhone size={24} />
                  <p>042 000 000 000</p>
                </div>
                <div className="d-flex">
                  <CiMail size={24} />
                  <p>contact@ennam.pk</p>
                </div>
                <div className="d-flex gap-4">
                  <FaYoutube size={24} />
                  <FaInstagram size={24} />
                  <FaFacebookF size={24} />
                  <FaTwitter size={24} />
                </div>
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.080388163466!2d74.31333827442266!3d31.466974749780317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391906abb85c74dd%3A0x355f6b3dda977640!2sAmanah%20Mall!5e0!3m2!1sen!2s!4v1734705641772!5m2!1sen!2s"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              {/* enaam <sub>3</sub>enaam<sup>5</sup> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactForm;
