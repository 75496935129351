import React from "react";

const UndefinedPage = () => {
  return (
    <main className="d-flex justify-content-center align-items-center" style={{height:"100vh",}}>
      404 Page
    </main>
  );
};

export default UndefinedPage;
