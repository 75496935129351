import { atom } from "recoil";

export const textState = atom({
    key: 'textState', 
    default: 'Komail',
  });
export const textState2 = atom({
    key: 'textState2', 
    default: 'devs',
  });
export const DefaultUrl = atom({
    key: 'DefaultUrl', 
    default: 'http://172.16.1.114:3000',
  });
export const AccessToken = atom({
    key: 'AccessToken', 
    default: '',
  });
export const User = atom({
    key: 'User', 
    default: {},
  });


