import React from 'react'
import offer1 from "../../Assets/offer1.png"
import offer2 from "../../Assets/offer2.png"
import offer3 from "../../Assets/offer3.png"
const Offers = () => {
  return (
    <div className='container'>
    <div className="d-flex justify-content-center my-3">
            <img src={offer1} className='img-fluid' alt="offer1" />
    </div>
    <h1 className='text-center mt-3'>Limited Time Offers</h1>
    <div className="row mt-md-3 mt-3">
        <div className="col-md-6 mt-2">
            <img src={offer2} className='img-fluid' alt="" />
        </div>
        <div className="col-md-6 mt-2">
            <img src={offer3} className='img-fluid' alt="" />
        </div>
    </div>
    </div>
  )
}

export default Offers
